<template>
  <div class="c-game">
    <div class="game-menu">
      <ul>
        <li><router-link :to="{name: 'GameCats'}"><i class="fas fa-dice-d6"></i> เล่นเกม</router-link></li>
        <li><router-link :to="{name: 'GameWinLose'}"><i class="far fa-list-alt"></i> สรุป ได้-เสีย</router-link></li>
        <!-- <li><router-link :to="{name: 'GameCredit'}"><i class="fas fa-coins"></i> เติม/ถอน เครดิต</router-link></li> -->
        <li><router-link :to="{name: 'GameHistory'}"><i class="fas fa-history"></i> รายการเล่น</router-link></li>
      </ul>
      <div class="credit-game px-2">
        <span class="alert-warning">เครดิตเกม ฿{{ balance | amountFormat(2, '0.00') }}</span>
      </div>
    </div>
    <CContainer class="p-0">
      <transition name="fade" mode="out-in">
        <router-view :key="$route.path"></router-view>
      </transition>
    </CContainer>
  </div>
</template>
<script>
import GameService from '@/services/GameService'
import cAlert from '@/helpers/alert'
import { database } from '@/firebaseConfig'
import { ref, onValue, onChildAdded, onChildChanged, onChildRemoved } from 'firebase/database'

export default {
  name: 'Game',
  data() {
    return {
      dataRef: null,
      rtdBalance: null
    }
  },
  computed: {
    balance() {
      return this.rtdBalance || this.$store.state.gameAccount?.balance || 0
    }
  },
  methods: {
    getCredit() {
      GameService.getBalance()
      .then((response)=>{
        if(response.success) {
          this.$store.commit('updateGameCredit', response.data)

          if(!this.dataRef) {
            this.dataRef = ref(database, `${process.env.VUE_APP_FIREBASE_RTD_DATABASE_PATH}/${response.data.id}`)

            onValue(this.dataRef, (snapshot) => {
              this.rtdBalance = snapshot.val()
            }, (error) => {
              console.error('Error: ', error);
            });
          }
        }else{
          throw new Error(response?.message || ( response?.data ? JSON.stringify(response.data) : null ))
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'แจ้งเตือน!',
          text: 'กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    }
  },
  mounted() {
    this.getCredit()
  }
}
</script>
<style lang="scss" scoped>
.c-game {
  background-color: #FFFFFF;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  .game-menu {
    background-color: #E5881E;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .credit-game {
      span {
        background-color: #FFFFFF;
        border-radius: 10px;
        padding: 2px 5px;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: flex;

      li:first-child {
        a {
          border-top-left-radius: 5px;
        }
      }

      li {
        list-style: none;

        a {
          display: block;
          padding: 8px 24px;
          font-size: 100%;
          color: #FFFFFF;
          text-decoration: none;
        }

        a:hover, a.active {
          background-color: #C63D2F;
        }
      }
    }
  }
}
</style>
